import React, { useState, useEffect, useContext, useRef } from 'react';
import { SensorContext } from '../../contexts/SensorContext';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useClasses from '../../hooks/styles';
import { useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Container from "@mui/material/Container";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { Bar, Line } from "react-chartjs-2";
import Dygraph from "dygraphs";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { ColorRing } from 'react-loader-spinner';
import './GraphBox.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const { DateTime } = require("luxon");

function GraphBox(props) {
    // Get global context
    const { sensor, setSensor } = useContext(SensorContext);
    console.log(sensor);
    console.log("GRAPH CONTEXT CHECK");

    const theme = useTheme();

    const styles = theme => ({
        paper: {
                marginTop: theme.spacing(8),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
        },
        avatar: {
                margin: theme.spacing(1),
                backgroundColor: theme.palette.secondary.main,
        },
        form: {
                width: '100%', // Fix IE 11 issue.
                marginTop: theme.spacing(1),
        },
        submit: {
                margin: theme.spacing(3, 0, 2),
        },
        white: {
                backgroundColor: 'white'
        }
    });
    const classes = useClasses(styles);

    // State values
    const [fulldata, setFullData] = useState([]);
    const [data, setData] = useState([]);
    const [range, setRange] = useState(props.range);
    const [ready, setReady] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    
    // Menu stuff
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        console.log("Trying to handlerange");
        console.log(range_options[index].value);
        handleRange(range_options[index].value);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const values
    const max_range = 7;
    const range_options = [
        { "value": 1, "label": "Select date range" },
        { "value": 1, "label": "Yesterday" },
        { "value": 3, "label": "Last 3 days" },
        { "value": 7, "label": "Since last week" }
    ];

    // Notification message stuff
    let notificationMessage = true;
    const notification_chip_label = `Testing: Sensor ${ sensor.serial_number } recently detected a leak!`;

    // Dygraph stuff
    var graphStyle = {
        width: "100%",
        height: 500
    };

    function legendFormatter(data) {
        if (data.x == null) {
          // This happens when there's no selection and {legend: 'always'} is set.
          return +data.series
            .map(function (series) {
              return series.dashHTML + " " + series.labelHTML;
            })
            .join();
        }
      
        var html = "<b>" + data.xHTML + "</b>";
        data.series.forEach((series) => {
          if (!series.isVisible) return;
      
          var labeledData = series.labelHTML + " <b>" + series.yHTML + "</b>";
      
          if (series.isHighlighted) {
            labeledData = "<b>" + labeledData + "</b>";
          }
      
          html +=
            "<div class='dygraph-legend-row'>" +
            series.dashHTML +
            "<div>" +
            labeledData +
            "</div></div>";
        });
        return html;
    }
    
    const graph_presets = {
        legend: "follow",
        legendFormatter: legendFormatter,
        title: "Test",
        labels: [ "Date", "Temperature", "Humidity" ],
        x: {
            //drawAxis: true,
            axisLabelFormatter: function(d) {
                return d.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                });
            },
        },
        ylabel: "Temperature (C) / Humidity (%)",
        //fillGraph: true,
        rollPeriod: 1,
        //visibility: [true, true],
        animatedZooms: true,
        showRangeSelector: true,
        hideOverlayOnMouseOut: true,
    };
    const graph_element = useRef(null);

    console.log("IN GRAPHBOX");
    console.log(props.serial);
    console.log(range);

    const history = useNavigate();
    useEffect(() => {
        // Runs when a sensor is selected
        // Fetches max amount of sensor data
        // Later filtered before rendered as graph
	    console.log("IN FETCH!");
        let isMounted = true;
        
        // Calculate various date figures
        const user_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const currentTime = DateTime.now();
        const targetTime = currentTime.startOf('day').minus({ days: max_range }); // Get weeks worth of data
        console.log(targetTime);
        
        // Organize the rest api url
        const request_fields = `fields[0]=createdAt&fields[1]=temperature&fields[2]=humidity`;
        const request_filters = `filters[$and][0][sensor][serial_number][$eq]=${sensor.serial_number}&filters[$and][1][createdAt][$gte]=${targetTime.toISO()}`;
        const request_pagination = `pagination[pageSize]=50000`;
        const request_sort = `sort=createdAt:DESC`;

        // Fetch data
        fetch(`${ process.env.REACT_APP_CMS_URL }/api/data?${ request_fields }&${ request_filters }&${ request_pagination }&${ request_sort }`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("access"),
            },
            }).then(res => {
                if (res?.ok) {
                    return res.json();
                } else {
                    throw res?.status;
                }
            })
            .then(json => {
                // After catching data, organize and distribute data
                var temp = [], humidity = [], date = [];
                let i;
                console.log("====================CAUGHT DATA=======================")
                console.log(json);
                console.log(json.data);
                console.log(json.data.length);
                if (json.data.length > 0) {
                    for (i = (json.data.length - 1); i > -1; i -= 1) {
                        temp.push(json.data[i].temperature);
                        humidity.push(json.data[i].humidity);
                        date.push(json.data[i].createdAt);
                    }
                }
		        if (isMounted) {
                    console.log("setting sensor data");
                    console.log(temp);
                    console.log(humidity);
                    console.log(date);
                    //setData({temp: temp, humidity: humidity, date:date});
                    setFullData(json.data);
		            setReady(true);
                }
            })
            .catch(error => {
                console.log(`HTTP Response Code: ${error}`);
                localStorage.removeItem("access");
                localStorage.removeItem("refresh");
                history("/");
            });

        return () => { isMounted = false }
    }, [sensor]);

    useEffect(() => {
        // Filter the fulldata array based on range to be rendered as graph
        // Runs whenever range or fulldata variables are changed
        console.log("IN FILTER");

        // Ignore filter if range is already the max range
        console.log(range)
        console.log(max_range);
        if (range < max_range) {
            // Calculate cutoff time based on range
            const currentTime = DateTime.now();
            const cutoffTime = currentTime.startOf('day').minus({ days: range }); // Set cutoff date
            console.log(fulldata);
            console.log(fulldata[0]);
            console.log(cutoffTime);
            console.log(fulldata.filter((datapoint) => datapoint.createdAt >= cutoffTime.toISO()));
            console.log("==========================================================");

            // Filter full data variable based on cutoff
            setData(fulldata.filter((datapoint) => datapoint.createdAt >= cutoffTime.toISO()));
        } else {
            setData(fulldata);
        }
    }, [fulldata, range]);

    useEffect(() => {

        const formatted_data = data.reduce((accumulator, obj) => {
            accumulator.push([new Date(obj.createdAt), obj.temperature, obj.humidity]);
            return accumulator;
        }, []);
        //new Dygraph(graph_element.current, formatted_data.reverse(), graph_presets); // Needs to reverse due to input data being in desc order

    }, [data]);

    function handleRange(range) {
        console.log("in handle range");
        setRange(range);
    }

    function closeNotification() {
        console.log("on delete");
        notificationMessage = false;
    }

    if (!ready) {
	    return (
            <div class = "loading_div">
                <ColorRing
                    visible={true}
                    height="80"
                    width="center"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                />

                <div ref={graph_element} />
            </div>
        );
    } else {
        let graph_title = `Sensor ${sensor.location}`;

        const plugins = [{
            id: 'customCanvasBackgroundColor',
            beforeDraw: (chart, args, options) => {
              const {ctx} = chart;
              ctx.save();
              ctx.globalCompositeOperation = 'destination-over';
              ctx.fillStyle = options.color || '#99ffff';
              ctx.fillRect(0, 0, chart.width, chart.height);
              ctx.restore();
            }
        }];

        const options = {
            type: 'line',
            radius: 0,
            responsive: true,
            maintainAspectRatio: true,
            animation: false,
            fill: false,
            spanGaps: false,
            interaction: {
                intersect: false,
                mode: 'index',
            },
            plugins: {
                title: {
                    display: true,
                    text: `${ graph_title }`,
                },
                customCanvasBackgroundColor: {
                    color: 'white',
                },
                decimation: {
                    enabled: true,
                    algorithm: "min-max"
                },
                tooltip: {
                    position: "nearest"
                }
            },
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: 'hour',
                        displayFormats: {
                            hour: 'HH:mm',
                            day: 'MMM DD',
                        },
                        tooltipFormat: 'D MMM YYYY - HH:mm:ss'
                    },
                    ticks: {
                        stepSize: 2,
                        major: {
                            enabled: true,
                            fontStyle: 'bold',
                            fontSize: 14
                        },
                        color: "black",
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: "Temperature (Celsius) / Humidity (%)",
                    },
                    ticks: {
                        color: "black",
                        font: {
                            weight: "bold"
                        }
                    }
                }
            }
        };

        const temp_data = [];
        const humidity_data = [];
        data.map(datapoint => {
            const temp_point = { x: datapoint.createdAt, y: datapoint.temperature};
            const humidity_point = { x: datapoint.createdAt, y: datapoint.humidity};
            temp_data.push(temp_point);
            humidity_data.push(humidity_point);
        });
        const formatted_data = {
            datasets: [
                {
                    label: "Temparature (Celcius)",
                    data: temp_data,
                    borderColor: "rgb(255, 99, 132, 1)",
                    backgroundColor: 'rgb(255, 99, 132, 1)',
                    borderWidth: 1,
                    normalized: true
                },
                {
                    label: "Humidity",
                    data: humidity_data,
                    borderColor: "rgb(53, 162, 235, 1)",
                    backgroundColor: 'rgb(53, 162, 235, 1)',
                    borderWidth: 1,
                    normalized: true
                }
            ]
        }

        return (

            <Box sx={{
                mx: "auto",
            }}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                        <EqualizerIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                        Dashboard
                        </Typography>
                    </div>
                </Container>

                <List
                    component="nav"
                    aria-label="Device settings"
                    sx={{ bgcolor: 'background.paper' }}
                >
                    <ListItemButton
                        id="date-selector-button"
                        aria-haspopup="listbox"
                        aria-controls="date-selector-menu"
                        aria-label="Date Range"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClickListItem}
                    >
                        <ListItemText
                            primary="Date Range"
                            secondary={range_options[selectedIndex].label}
                        />
                    </ListItemButton>
                </List>
                <Menu
                    id="date-selector-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'date-selector-button',
                        role: 'listbox',
                    }}
                >
                    {range_options.map((option, index) => (
                        <MenuItem
                            key={option}
                            disabled={index === 0}
                            selected={index === selectedIndex}
                            onClick={(event) => handleMenuItemClick(event, index)}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Menu>

                <Line 
                    data={formatted_data}
                    options={options}
                    plugins={plugins}
                />

                <div class="notificationMessage">
                    {notificationMessage &&
                        <Chip 
                            label={notification_chip_label}
                            onDelete={closeNotification}
                        />
                    }
                </div>
            </Box>
        );

    }
}

export default GraphBox;
GraphBox.propTypes = {
    serial: PropTypes.string.isRequired,
    range: PropTypes.number.isRequired,
};
