import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import Typography from "@mui/material/Typography";

import "./SensorPage.css";
import Logo from "../Logo/Logo.js";
import NavBar from "../NavBar/NavBar";
import { get_sensor } from "../../api/requests.js";

function SensorPage(props) {
  // Routing params
  const { serial_number } = useParams();

  const [{page_content, loading}, setStates] = useState({ page_content: null, loading: true });

  const history = useNavigate();

  useEffect(() => {
    console.log("FETCH SENSOR DATA");
    console.log(serial_number);
    console.log(`${ process.env.REACT_APP_CMS_URL }/api/sensors?filters[serial_number][$eq]=${ serial_number }&populate=user`);
    fetch(`${ process.env.REACT_APP_CMS_URL }/api/sensors?filters[serial_number][$eq]=${ serial_number }&populate=user`, {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
      },
    }).then(res => {
      if (res?.ok) {
        return res.json();
      } else {
        throw res?.status;
      }
    }).then(json => {
      console.log(json);
      if (json.data.length < 1) {
        history("/");
      } else {
        setStates({ page_content: json.data[0], loading:false });
      }
    });
  }, []);

  if (loading) {
    return "LOADING...";
  }

  console.log(page_content);
  const sensor_info = Object.keys(page_content).map(key => {
    console.log(key);
    if (key != "user") {
      return <Typography color="primary">
        { key }: { page_content[key] }
      </Typography>
    }
  });
  console.log(sensor_info);

  const user_info = Object.keys(page_content.user).map(key => {
    console.log(key);
    if (key != "user") {
      return <Typography color="primary">
        { key }: { page_content.user[key] }
      </Typography>
    }
  });

  return (
    <div>
      <div class="logo">
        <Logo />
        <div class="navBar">
          <NavBar content={page_content} />
        </div>
      </div>
      ===============================================
      { sensor_info }
      ===============================================
      { user_info }
    </div>
  );
}

export default SensorPage;
